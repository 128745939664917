import { FlexBox, theme, useClickOutSide } from '@rario/shared-components'
// import BulletPointIcon from 'assets/svg/BulletPointIcon'
// import CrossIcon from 'assets/svg/CrossIcon'
// import DownArrowIcon from 'assets/svg/DownArrowIcon'
// import HamburgerIcon from 'assets/svg/HamburgerIcon'
// import NavigateIcon from 'assets/svg/NavigateIcon'
// import { DropIcon } from 'components/FaqSection/Faq.styles'
// import { BgOverlay, Sidenav } from 'components/Header/Header.styles'
// import { HOME_LANDING_MENU } from 'components/Header/MenuListData'
// import Logo from 'components/Logo'
import { SeparationLine } from 'components/PlayerProfileSection/PlayerProfileSection.styles'
// import usePageControl from 'hooks/usePageControl'
// import { RouteChildren, ThemeEnum } from 'interfaces'
// import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
// import { isIos } from 'utils/utils'
import { HeaderWrapper } from './HomeLanding.styles'
// import MenuFooter from './MenuFooter'
// import { PLATFORM_ORIGIN } from 'config'
// import HeaderLogo from 'components/Header/HeaderLogo'
import RarioBadge from 'assets/svg/RarioBadge'

const HomeLandingHeader = () => {
  const SidenavRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  // const [openMenuName, setOpenMenuName] = useState<string | undefined | null>(null)
  // const [openSecondMenuName, setOpenSecondMenuName] = useState<string | undefined | null>(null)
  // const [openThirdMenuName, setOpenThirdMenuName] = useState<string | undefined | null>(null)

  // const { isDream11Landing } = usePageControl()

  useEffect(() => {
    const body: HTMLBodyElement | null = document.querySelector('body')
    if (isMenuOpen) {
      body?.classList.add('noscroll')
    }
    return () => {
      body?.classList.remove('noscroll')
    }
  }, [isMenuOpen])

  // const isIosDevice = isIos()

  useClickOutSide(SidenavRef, () => setIsMenuOpen(false))
  return (
    <>
      <HeaderWrapper pt={18}>
        <FlexBox
          px={15}
          pb={'20px'}
          mx="auto"
          width="100%"
          alignItems="center"
          position={'relative'}
        >
          <RarioBadge color={theme.colors.secondary} />
          {/* <FlexBox alignItems={'center'} ml="auto" onClick={() => setIsMenuOpen(true)}>
            <HamburgerIcon />
          </FlexBox> */}

          {/* <>
            <Sidenav
              height={'100vh'}
              width={'65%'}
              position={['fixed', 'absolute']}
              zIndex={9999999}
              top={[0, '-20px']}
              right={isMenuOpen ? 0 : '-100%'}
              background={theme.colors.richBlackLight}
              backgroundPosition={'center'}
              backgroundSize="cover"
              overflowX="hidden"
              backgroundRepeat={'no-repeat'}
              pt={26}
              ref={SidenavRef}
            >
              <FlexBox width="100%" flexDirection="column" justifyContent={'space-between'}>
                <FlexBox width={'100%'} flexDirection={'column'} pb={40} px={15}>
                  <FlexBox
                    px={15}
                    onClick={() => {
                      setIsMenuOpen(false)
                    }}
                    justifyContent={'flex-end'}
                    cursor={'pointer'}
                  >
                    <CrossIcon />
                  </FlexBox>

                  <FlexBox
                    alignSelf={'center'}
                    py={'22px'}
                    width={'fit-content'}
                    borderBottom={`1px solid ${theme.colors.whites[3]}`}
                  >
                    <TextFieldWrapper
                      cursor="pointer"
                      as="a"
                      href={'/login'}
                      content="LOGIN"
                      mr={'11px'}
                    />
                    <SignupButtonWrapper cursor="pointer">
                      <TextFieldWrapper
                        content="SIGNUP"
                        as="a"
                        href={'/signup'}
                        color={isDream11Landing ? theme.colors.white : theme.colors.secondary}
                      />
                    </SignupButtonWrapper>
                  </FlexBox>

                  {(HOME_LANDING_MENU || []).map(
                    ({ link, name, selected, newTab, submenu, child = [], cr = true }, index) => {
                      if (PLATFORM_ORIGIN === ThemeEnum.CR && !cr) {
                        return <></>
                      }
                      return (
                        <Fragment key={index}>
                          <FlexBox
                            width={'100%'}
                            onClick={() => {
                              if (submenu) {
                                openMenuName === name
                                  ? setOpenMenuName(null)
                                  : setOpenMenuName(name)
                              } else {
                                setIsMenuOpen(!isMenuOpen)
                              }
                            }}
                            key={name}
                            alignItems={'center'}
                          >
                            <TextField
                              as={link ? 'a' : 'span'}
                              href={link}
                              target={newTab ? '_blank' : '_self'}
                              fontWeight="400"
                              fontSize={'16px'}
                              py={20}
                              lineHeight="20px"
                              fontFamily={theme.fonts.primary}
                              letterSpacing={theme.letterSpacings.primary}
                              opacity={selected ? 1 : 0.6}
                              cursor={'pointer'}
                            >
                              {name}
                            </TextField>
                            {submenu && (
                              <DropIcon ml={'10px'} isFaqOpen={openMenuName === name}>
                                <DownArrowIcon
                                  height="27px"
                                  width="13px"
                                  fill={theme.colors.whites[7]}
                                />
                              </DropIcon>
                            )}
                          </FlexBox>

                          {submenu && openMenuName === name && (
                            <FlexBox flexDirection="column" px={'12px'}>
                              {!!child.length &&
                                child.map((item: RouteChildren, i: number) => (
                                  <Fragment key={i}>
                                    <FlexBox
                                      alignItems="center"
                                      py={'10px'}
                                      flexDirection={'row'}
                                      justifyContent={'flex-start'}
                                    >
                                      <Link passHref href={item?.link as string}>
                                        <a target={item?.newTab ? '_blank' : '_top'}>
                                          <TextField
                                            mr={'9px'}
                                            pl={item?.icon ? '18px' : '5px'}
                                            pb={0}
                                            fontSize="14px"
                                            lineHeight="16.59px"
                                            fontWeight={3}
                                            fontFamily={theme.fonts.primary}
                                            letterSpacing={theme.letterSpacings.primary}
                                            opacity={
                                              selected || openSecondMenuName === item?.name
                                                ? 1
                                                : 0.6
                                            }
                                            cursor={'pointer'}
                                          >
                                            {item?.name}
                                          </TextField>
                                        </a>
                                      </Link>

                                      <FlexBox
                                        onClick={() => {
                                          openSecondMenuName === item?.name
                                            ? setOpenSecondMenuName(null)
                                            : setOpenSecondMenuName(item?.name)
                                        }}
                                      >
                                        {item?.rightIcon}
                                      </FlexBox>
                                    </FlexBox>
                                    {item?.child &&
                                      openSecondMenuName === item?.name &&
                                      item?.child.map((secondChild: RouteChildren, i: number) => {
                                        return (
                                          <FlexBox
                                            mb="10px"
                                            flexDirection={'column'}
                                            alignItems="flex-start"
                                            key={i}
                                          >
                                            <FlexBox ml="12px" alignItems={'center'} margin="5px">
                                              {!secondChild?.rightIcon && <NavigateIcon />}
                                              <Link passHref href={secondChild?.link as string}>
                                                <a target={secondChild?.newTab ? '_blank' : '_top'}>
                                                  <TextField
                                                    mx={'9px'}
                                                    pl={secondChild?.icon ? '18px' : '0px'}
                                                    pb={0}
                                                    fontSize="14px"
                                                    fontFamily={theme.fonts.primary}
                                                    letterSpacing={theme.letterSpacings.primary}
                                                    lineHeight="16.59px"
                                                    fontWeight={3}
                                                    opacity={
                                                      selected ||
                                                      openThirdMenuName === secondChild?.name
                                                        ? 1
                                                        : 0.6
                                                    }
                                                  >
                                                    {secondChild?.name}
                                                  </TextField>
                                                </a>
                                              </Link>

                                              <FlexBox
                                                onClick={() => {
                                                  openThirdMenuName === secondChild?.name
                                                    ? setOpenThirdMenuName(null)
                                                    : setOpenThirdMenuName(secondChild?.name)
                                                }}
                                              >
                                                {secondChild?.rightIcon}
                                              </FlexBox>
                                            </FlexBox>
                                            {openThirdMenuName === secondChild?.name &&
                                              secondChild?.child?.map(
                                                (thirdChild: RouteChildren, index: number) => {
                                                  return (
                                                    <FlexBox
                                                      padding="5px"
                                                      justifyContent={'center'}
                                                      alignItems="center"
                                                      key={index}
                                                      ml="25px"
                                                    >
                                                      {thirdChild?.icon ? (
                                                        thirdChild?.icon
                                                      ) : (
                                                        <BulletPointIcon opacity={0.9} />
                                                      )}
                                                      <Link
                                                        passHref
                                                        href={thirdChild?.link as string}
                                                      >
                                                        <a
                                                          target={
                                                            thirdChild?.newTab ? '_blank' : '_top'
                                                          }
                                                        >
                                                          <TextField
                                                            fontWeight={400}
                                                            fontSize="14px"
                                                            pl="13px"
                                                            lineHeight={'16px'}
                                                            whiteSpace="pre"
                                                            fontFamily={theme.fonts.primary}
                                                            letterSpacing={
                                                              theme.letterSpacings.primary
                                                            }
                                                            pb="0"
                                                            content={thirdChild?.name}
                                                            opacity={1}
                                                          />
                                                        </a>
                                                      </Link>
                                                    </FlexBox>
                                                  )
                                                }
                                              )}
                                          </FlexBox>
                                        )
                                      })}
                                  </Fragment>
                                ))}
                            </FlexBox>
                          )}
                        </Fragment>
                      )
                    }
                  )}
                </FlexBox>
                {!isIosDevice && PLATFORM_ORIGIN === ThemeEnum.RARIO && (
                  <FlexBox py={'50px'}>
                    <MenuFooter />
                  </FlexBox>
                )}
              </FlexBox>
            </Sidenav>
          </> */}
        </FlexBox>
        {/* {isMenuOpen && <BgOverlay />} */}
      </HeaderWrapper>
      <SeparationLine zIndex={9999} />
    </>
  )
}

export default HomeLandingHeader
