import { FlexBox, Image, TextField, theme } from '@rario/shared-components'
import { Media } from 'interfaces/cmsContentTypes'
import React, { useCallback } from 'react'
import { StepsTitleBox } from './HomeLanding.styles'

const LandingFirstFoldBottomSteps: React.FunctionComponent<{
  step1?: Media
  step2?: Media
  step3?: Media
}> = ({ step1, step2, step3 }) => {
  const stepItem = useCallback((image: string, title: string, index: number) => {
    return (
      <>
        <FlexBox
          key={`steps-${index}`}
          flexDirection={'column'}
          width={index === 1 ? '90px' : '86px'}
          justifyContent={'center'}
          alignItems={'center'}
          mx={index === 1 ? '15px' : '0px'}
        >
          <FlexBox>
            <Image src={image} name={`steps-${index}`} />
          </FlexBox>

          <StepsTitleBox mt={'9px'}>
            <TextField
              textAlign={'center'}
              fontSize={'12px'}
              fontWeight={400}
              lineHeight={'14px'}
              primaryHighlightColor={theme.colors.secondary}
              highlightColor={theme.colors.darkGold}
              color={theme.colors.greyishWhite}
              content={title}
            />
          </StepsTitleBox>
        </FlexBox>
      </>
    )
  }, [])
  return (
    <FlexBox width={'100%'} justifyContent={'space-around'} zIndex={9999999} mb={'5px'}>
      {step1 && stepItem(step1?.file?.url, step1?.description, 0)}
      {step2 && stepItem(step2?.file?.url, step2?.description, 1)}
      {step3 && stepItem(step3?.file?.url, step3?.description, 2)}
    </FlexBox>
  )
}

export default LandingFirstFoldBottomSteps
