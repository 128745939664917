import { Button, theme } from '@rario/shared-components'
import AndroidIcon from 'assets/svg/AndroidIcon'
import { CTA } from 'interfaces/cmsContentTypes'
import { CTAVariant, CTAVariantEnum } from 'interfaces/homePage'
import Link from 'next/link'
import React from 'react'
import { Dream11FFCTAWrapper } from './HomeLanding.styles'

const LandingButtons: React.FunctionComponent<{
  variant: CTAVariant
  primaryCta?: CTA
  secondaryCta?: CTA
}> = ({ variant, primaryCta, secondaryCta }) => {
  switch (variant) {
    case CTAVariantEnum.SINGLE_PRIMARY_CTA:
      return (
        <>
          {primaryCta && (
            <Dream11FFCTAWrapper
              flexDirection="column"
              mt={'16px'}
              width={'100%'}
              justifyContent={'center'}
              alignItems="center"
              pb="20px"
              gridGap="5px"
            >
              <Link href={primaryCta?.link} passHref>
                <a target={primaryCta?.openInNewTab ? '_blank' : '_self'}>
                  <Button
                    primaryEdges
                    leftIcon={primaryCta?.link === '/app-download' ? <AndroidIcon /> : <></>}
                    cornerIndentation="16px"
                    name={primaryCta?.text}
                    mx={'12px'}
                    variant={'dynamic'}
                    colorVariant="greenFilled"
                    fontSize={'16px'}
                    px={'21px'}
                    textTransform={'uppercase'}
                    fontWeight={600}
                    width={'305px'}
                  />
                </a>
              </Link>
            </Dream11FFCTAWrapper>
          )}
        </>
      )
    case CTAVariantEnum.ONE_PRIMARY_ONE_SECONDARY_CTA:
      return (
        <>
          <Dream11FFCTAWrapper
            mt={'16px'}
            width={'100%'}
            justifyContent={'center'}
            alignItems="center"
            pb="20px"
          >
            {primaryCta && (
              <Link href={primaryCta?.link} passHref>
                <a target={primaryCta?.openInNewTab ? '_blank' : '_self'}>
                  <Button
                    primaryEdges
                    leftIcon={primaryCta?.link === '/app-download' ? <AndroidIcon /> : <></>}
                    cornerIndentation="16px"
                    name={primaryCta?.text}
                    variant={'dynamic'}
                    colorVariant="primaryFilled"
                    fontSize={'14px'}
                    px={'20px'}
                    textTransform={'uppercase'}
                    fontWeight={600}
                    mr={'15px'}
                  />
                </a>
              </Link>
            )}

            {secondaryCta && (
              <Link href={secondaryCta?.link} passHref>
                <a target={secondaryCta?.openInNewTab ? '_blank' : '_self'}>
                  <Button
                    leftIcon={secondaryCta?.link === '/app-download' ? <AndroidIcon /> : <></>}
                    name={secondaryCta?.text}
                    mx={'12px'}
                    variant={'underline'}
                    fontSize={'14px'}
                    px={'21px'}
                    textTransform={'uppercase'}
                    fontWeight={600}
                  />
                </a>
              </Link>
            )}
          </Dream11FFCTAWrapper>
        </>
      )
    case CTAVariantEnum.TWO_PRIMARY_CTA:
      return (
        <>
          <Dream11FFCTAWrapper
            mt={'16px'}
            width={'100%'}
            justifyContent={'center'}
            alignItems="center"
            pb="20px"
          >
            {primaryCta && (
              <Link href={primaryCta?.link} passHref>
                <a target={primaryCta?.openInNewTab ? '_blank' : '_self'}>
                  <Button
                    primaryEdges
                    cornerIndentation="16px"
                    name={primaryCta?.text}
                    variant={'dynamic'}
                    colorVariant="whiteFilled"
                    fontSize={'14px'}
                    px={'20px'}
                    textTransform={'uppercase'}
                    fontWeight={600}
                    mr={'15px'}
                    borderColor={theme.colors.wolfram}
                    borderWidth={'2px'}
                  />
                </a>
              </Link>
            )}

            {secondaryCta && (
              <Link href={secondaryCta?.link} passHref>
                <a target={secondaryCta?.openInNewTab ? '_blank' : '_self'}>
                  <Button
                    primaryEdges
                    leftIcon={
                      secondaryCta?.link === '/app-download' ? (
                        <AndroidIcon fill={theme.colors.primaryText} />
                      ) : (
                        <></>
                      )
                    }
                    cornerIndentation="16px"
                    name={secondaryCta?.text}
                    variant={'dynamic'}
                    colorVariant="primaryFilled"
                    fontSize={'14px'}
                    px={'20px'}
                    textTransform={'uppercase'}
                    fontWeight={600}
                  />
                </a>
              </Link>
            )}
          </Dream11FFCTAWrapper>
        </>
      )
    default:
      return (
        <>
          <Dream11FFCTAWrapper
            flexDirection="column"
            mt={'16px'}
            width={'100%'}
            justifyContent={'center'}
            alignItems="center"
            pb="20px"
            gridGap="5px"
          >
            <Link href={'/app-download'} passHref>
              <a>
                <Button
                  primaryEdges
                  leftIcon={<AndroidIcon />}
                  cornerIndentation="16px"
                  name={'GET THE APP & PARTICIPATE'}
                  mx={'12px'}
                  variant={'dynamic'}
                  colorVariant="greenFilled"
                  fontSize={'16px'}
                  px={'21px'}
                  textTransform={'uppercase'}
                  fontWeight={600}
                  width={'282px'}
                />
              </a>
            </Link>
          </Dream11FFCTAWrapper>
        </>
      )
  }
}

export default LandingButtons
