import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const BoxContainer = customStyled(FlexBox)`
background: radial-gradient(39.31% 38.84% at 22.5% 73.77%, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(255, 255, 255, 0.04);
box-shadow: 0px 14px 14px -5px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(15px);
border-radius: 20px;
&::before{
    content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 20px;
      border: 1px solid transparent;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.04)) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
}
`

export const SeparationLine = customStyled(FlexBox)<{ theme?: ThemeType }>`
    width: 100%;
    height:1px;
    background:${({ theme }) => theme.gradients.primaryBoder};
    
  }
`
