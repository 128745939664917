import { FlexBox, Image } from '@rario/shared-components'

const CashbackSection: React.FunctionComponent<{ image?: string; name?: string }> = ({
  image,
  name,
}) => {
  return (
    <FlexBox width="100%" height="100%" zIndex={1001} position="relative" top="-15px">
      {image && <Image src={image} name={name} width="100%" />}
    </FlexBox>
  )
}

export default CashbackSection
