import React from 'react'
import { TextSeparator, TickerWrapper } from 'components/Header/Header.styles'
import { getCDNUrl } from 'utils/utils'
import { FlexBox, TextField, theme } from '@rario/shared-components'
import Marquee from 'react-fast-marquee'
import { homepageTickerData } from 'constant'

const HomeLandingTicker: React.FunctionComponent<{
  tickerData?: string[]
  tickerImage?: string
}> = ({
  tickerData = homepageTickerData,
  tickerImage = getCDNUrl('/App/images/rario-landing/ticker-bg.png'),
}) => {
  return (
    <TickerWrapper
      backgroundImage={`url(${tickerImage})`}
      backgroundSize={'cover'}
      zIndex={999}
      alignItems={'center'}
    >
      <Marquee speed={40} gradient={false}>
        {tickerData?.map((heading, index) => {
          return (
            <FlexBox alignItems={'center'} key={index}>
              <TextField
                fontSize={'20px'}
                lineHeight={'28px'}
                fontFamily={theme.fonts.primary}
                letterSpacing={theme.letterSpacings.primary}
                fontWeight={700}
                content={heading}
                textTransform={'uppercase'}
                color={theme.colors.black}
                primaryHighlightColor={theme.colors.white}
              />
              <TextSeparator background={theme.colors.black} borderRadius={'50%'} />
            </FlexBox>
          )
        })}
      </Marquee>
    </TickerWrapper>
  )
}
export default HomeLandingTicker
