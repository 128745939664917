import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const StepNumberContainer = customStyled(FlexBox)<{
  theme?: ThemeType
  color?: string
  isHeadingLarge?: boolean
}>`
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
  
    span {
      background: ${({
        color = 'linear-gradient(180deg, #E2B872 0%, rgba(226, 184, 114, 0.6) 100%)',
      }) => color};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      ${({ isHeadingLarge = false }) =>
        isHeadingLarge &&
        `@media(max-width: 360px) {
          font-size: 40px;
          line-height: 36px;
      }`}
      
    }
  
`
export const StepsContainer = customStyled(FlexBox)<{ isOpen?: boolean }>`
    max-height: ${({ isOpen = false }) => (isOpen ? '800px' : '0px')};
    transition: max-height 0.4s ease-in-out;
    flex-direction: column;
`

export const HeadingContainer = customStyled(FlexBox)<{ fitContent?: boolean }>`
    @media(max-width: ${({ fitContent = true }) => (fitContent ? '375px' : '330px')}){
      width: 80%;
    }
`
export const FirstFoldBottomWrapper = customStyled(FlexBox)`
    transform: matrix(1, 0, 0, -1, 0, 0);
`
export const TeamItemButtonWrapper = customStyled(FlexBox)`
    transform: translateX(-50%);
`

export const HeadingContent = customStyled.div<{
  totalItems: number
  fractions: number
  fitContent?: boolean
}>`
  overflow: hidden;

  & p {
    @media(max-width: ${({ fitContent = true }) => (fitContent ? '375px' : '330px')}){
      font-size: 14px;
    }
    display: block;
    height: 100%;
    animation: spin_words ${({ totalItems }) => totalItems + 2}s infinite;
    
    @keyframes spin_words {
      ${({ fractions }) => {
        let animation = ''
        for (let i = 1; i <= fractions; i++) {
          if (i % 2 === 0) {
            const multiple = i / 2
            animation += `${(100 / fractions) * (i - 1)}% {
                transform: translateY(${-(100 * multiple + 12)}%);
              }
              ${(100 / fractions) * i}% {
                transform: translateY(${-100 * multiple}%);
              }
            `
          }
        }
        return animation
      }}
    }
  }
`
export const HeadingWrapper = customStyled(FlexBox)`
  box-sizing: content-box;
`
