import { Button, FlexBox, TextField, theme } from '@rario/shared-components'

import React from 'react'
import { getCDNUrl } from 'utils/utils'
// import AndroidIcon from 'assets/svg/AndroidIcon'
// import { scrollToFormAndFocus } from 'utils/useFormMethods'
import Link from 'next/link'
import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'

const HomeLandingFirstFold: React.FunctionComponent = () => {
  // const isAndroidDevice = isAndroid()
  // const isIosDevice = isIos()
  // const trackCTA = (eventName: string, ctaName: string) => {
  //   trackEvent('Section Interacted', 'click', {
  //     sub_category: 'home-banner_first_fold',
  //     type: 'banner',
  //     category: 'marketing_campaign',
  //     click_type: 'button',
  //     click_text: ctaName,
  //     click_action: eventName,
  //   })
  // }

  return (
    <>
      <FlexBox
        position={'relative'}
        flexDirection={'column'}
        zIndex={999}
        height={PLATFORM_ORIGIN === ThemeEnum.RARIO ? '600px' : '520px'}
        backgroundImage={`url(${getCDNUrl(
          '/App/images/rario-landing/first-fold-bg-v6.png',
          true
        )})`}
        backgroundPosition={'center'}
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
      >
        <FlexBox
          px={'10px'}
          left={0}
          right={0}
          top={PLATFORM_ORIGIN === ThemeEnum.RARIO ? 85 : 105}
          flexDirection={'column'}
          textAlign={'center'}
          alignItems={'center'}
          position={'absolute'}
        >
          <TextField
            primaryHighlightColor={theme.colors.secondary}
            fontSize={30}
            lineHeight={'30.6px'}
            fontWeight={800}
            fontFamily={theme.fonts.primary}
            letterSpacing={theme.letterSpacings.primary}
          >
            {PLATFORM_ORIGIN === ThemeEnum.RARIO ? 'JOIN DIGITAL' : 'JOIN CRICKET FANDOM'}
          </TextField>

          {PLATFORM_ORIGIN === ThemeEnum.RARIO && (
            <TextField
              color={theme.colors.secondary}
              fontSize={30}
              lineHeight={'30.6px'}
              fontWeight={800}
              fontFamily={theme.fonts.primary}
              letterSpacing={theme.letterSpacings.primary}
              mb={'8px'}
            >
              CRICKET FANDOM
            </TextField>
          )}
          <TextField
            opacity={0.6}
            fontSize={14}
            lineHeight={'16.59px'}
            fontWeight={'400'}
            fontFamily={theme.fonts.secondary}
          >
            Join contests with your digital player cards
          </TextField>
        </FlexBox>
        <FlexBox
          px={'27px'}
          flexDirection={'column'}
          textAlign={'center'}
          position={'absolute'}
          alignItems={'center'}
          bottom={25}
        >
          <TextField
            fontWeight={theme.fontWeights[3]}
            fontSize={'14px'}
            lineHeight={'20px'}
            color={theme.colors.platinum}
            primaryHighlightColor={theme.colors.secondary}
            content={
              PLATFORM_ORIGIN === ThemeEnum.RARIO
                ? 'Collect official **limited-edition** Digital Player Cards of top cricketers and play contests.'
                : 'Collect official limited-edition Digital Player Cards of top cricketers'
            }
          />

          {/* {isAndroidDevice && PLATFORM_ORIGIN === ThemeEnum.RARIO ? (
            <FlexBox mt={'16px'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Link href={'/app-download#installationSteps'} scroll={false} passHref>
                <a id={'download-app-btn'}>
                  <Button
                    primaryEdges
                    cornerIndentation="10px"
                    name={'GET THE APP'}
                    mx={'12px'}
                    onClick={() => apkDownloadHandler('home_download-android_cta', 'GET THE APP')}
                    variant={'dynamic'}
                    fontSize={'14px'}
                    rightIcon={<AndroidIcon fill={theme.colors.primaryText} />}
                  />
                </a>
              </Link>
              <Button
                name={'LEARN MORE'}
                mx={'12px'}
                variant={'underline'}
                fontSize={'14px'}
                lineHeight={'22px'}
                fontFamily={theme.fonts.primary}
                letterSpacing={theme.letterSpacings.primary}
                onClick={() => {
                  trackCTA('click_android_learn_more', 'LEARN MORE')
                  scrollToFormAndFocus({ className: 'how-it-works' })
                }}
              />
            </FlexBox>
          ) : isIosDevice && PLATFORM_ORIGIN === ThemeEnum.RARIO ? (
            <FlexBox mt={'16px'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Link href={'/signup'} passHref>
                <a id={'signup-btn'}>
                  <Button
                    primaryEdges
                    cornerIndentation="10px"
                    name={'SIGNUP'}
                    mx={'12px'}
                    variant={'dynamic'}
                    fontSize={'14px'}
                  />
                </a>
              </Link>
              <Button
                name={'LEARN MORE'}
                mx={'12px'}
                variant={'underline'}
                fontSize={'14px'}
                lineHeight={'22px'}
                fontFamily={theme.fonts.primary}
                letterSpacing={theme.letterSpacings.primary}
                onClick={() => {
                  trackCTA('click_ios_learn_more', 'LEARN MORE')
                  scrollToFormAndFocus({ className: 'how-it-works' })
                }}
              />
            </FlexBox>
          ) : (
            <FlexBox mt={'16px'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Link href={'/signup'} passHref>
                <a id={'signup-btn'}>
                  <Button
                    primaryEdges
                    cornerIndentation="10px"
                    name={'SIGNUP'}
                    mx={'12px'}
                    bgColor={theme.colors.greyishWhite}
                    borderColor={theme.colors.wolfram}
                    color={'black'}
                    variant={'dynamic'}
                    fontSize={'14px'}
                  />
                </a>
              </Link>
              {PLATFORM_ORIGIN === ThemeEnum.RARIO ? (
                <Link href={'/app-download'} passHref>
                  <a id={'get-app-btn'}>
                    <Button
                      primaryEdges
                      cornerIndentation="10px"
                      name={'GET THE APP'}
                      mx={'12px'}
                      onClick={() => {
                        ;(window as any)?.fbq?.('track', 'Download')
                      }}
                      variant={'dynamic'}
                      fontSize={'14px'}
                      rightIcon={<AndroidIcon fill={theme.colors.primaryText} />}
                    />
                  </a>
                </Link>
              ) : (
                <Link href={'/login'} passHref>
                  <a id={'login-btn'}>
                    <Button
                      primaryEdges
                      cornerIndentation="10px"
                      name={'LOGIN'}
                      mx={'12px'}
                      width={'100px'}
                      variant={'dynamic'}
                      fontSize={'14px'}
                    />
                  </a>
                </Link>
              )}
            </FlexBox>
          )} */}
          <Link href={'/login'} passHref>
            <a id={'login-btn'}>
              <Button
                primaryEdges
                cornerIndentation="10px"
                name={'LOGIN'}
                mx={'12px'}
                width={'130px'}
                variant={'dynamic'}
                fontSize={'14px'}
              />
            </a>
          </Link>
        </FlexBox>
      </FlexBox>
      {/* <HomeLandingTicker /> */}
    </>
  )
}

export default HomeLandingFirstFold
