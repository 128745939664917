import { FlexBox, Image, theme, Video } from '@rario/shared-components'
import React from 'react'
import { getCDNUrl } from 'utils/utils'
import { Dream11FFBottomWrapper } from './HomeLanding.styles'
import { StepNumberContainer } from 'components/IPLTeamLanding/IPLTeamLanding.styles'
import HomeLandingTicker from './HomeLandingTicker'
import CashbackSection from './CashbackSection'
import { CTAVariantEnum, FirstFoldData } from 'interfaces/homePage'
import LandingButtons from './LandingButtons'
import Link from 'next/link'
import LandingFirstFoldBottomSteps from './LandingFirstFoldBottomSteps'
import DescriptionField from 'components/DescriptionField'
import { FontMapping } from 'interfaces/cmsContentTypes'

const LandingFirstFold: React.FunctionComponent<{ data?: FirstFoldData }> = ({ data }) => {
  const videoSrc = data?.bannerMedia?.video?.file?.url || data?.bannerMedia?.videoUrl
  const backgroundImage = data?.backgroundImage?.file?.url
  const backgroundImageAlt = data?.backgroundImage?.title
  const headingBadge = data?.headingIcon?.file?.url
  const headingBadgeAlt = data?.headingIcon?.file?.url
  const isVideo = data?.bannerMedia?.isVideo

  const getVideoType = () => {
    let videoType = 'video/mp4'
    if (videoSrc?.endsWith('.mp4')) {
      videoType = 'video/mp4'
    } else if (videoSrc?.endsWith('.webm')) {
      videoType = 'video/webm'
    } else if (videoSrc?.endsWith('.mov')) {
      videoType = 'video/mov'
    }
    return videoType
  }

  return (
    <>
      <FlexBox position={'relative'} flexDirection={'column'} zIndex={999} overflowX={'hidden'}>
        {headingBadge && (
          <FlexBox
            width={'100%'}
            justifyContent={'center'}
            position={'absolute'}
            top={'23px'}
            zIndex={999999}
          >
            <Image width={'auto'} height={'68px'} src={headingBadge} name={headingBadgeAlt} />
          </FlexBox>
        )}
        {videoSrc && isVideo && (
          <FlexBox display="block">
            <Video
              src={videoSrc}
              poster={backgroundImage}
              mediaType={getVideoType()}
              controls={false}
              muted={true}
              playsinline={true}
              width={'100%'}
              loop
              autoPlay
              objectFit="contain"
            />
          </FlexBox>
        )}
        {backgroundImage && !isVideo && (
          <FlexBox position={'relative'} width={'100%'}>
            <Image width={'100%'} src={backgroundImage} name={backgroundImageAlt} />
            {data?.bannerMedia?.hyperlinkUrl && (
              <Link href={data?.bannerMedia?.hyperlinkUrl} passHref>
                <a target={data?.bannerMedia?.newTab ? '_blank' : '_self'}>
                  <FlexBox
                    top={'30%'}
                    width={'90%'}
                    position={'absolute'}
                    zIndex={999999}
                    height={'40%'}
                    left={'5%'}
                  />
                </a>
              </Link>
            )}
          </FlexBox>
        )}
        <FlexBox
          px={'5px'}
          left={0}
          right={0}
          top={headingBadge ? 90 : 60}
          flexDirection={'column'}
          textAlign={'center'}
          alignItems={'center'}
          position={'absolute'}
        >
          {data?.heading1 && data?.heading1.isVisible && (
            <DescriptionField
              description={data?.heading1?.content}
              paragraphStyles={{
                fontSize: data?.heading1?.fontSize ? `${data?.heading1?.fontSize}px` : '12px',
                color: data?.heading1?.fontColor || theme.colors.white,
                primaryHighlightColor: data?.heading1?.highlightColor || theme.colors.secondary,
                fontFamily: FontMapping.get(data?.heading1?.fontFamily),
                lineHeight: '18px',
              }}
            />
          )}

          <StepNumberContainer
            mb={'10px'}
            color={data?.heading2?.fontColor || theme.colors.greyishWhite}
            isHeadingLarge
          >
            {data?.heading2 && data?.heading2?.isVisible && (
              <DescriptionField
                description={data?.heading2?.content}
                paragraphStyles={{
                  fontSize: data?.heading2?.fontSize ? `${data?.heading2?.fontSize}px` : '40px',
                  padding: 0,
                  pr: '10px',
                  lineHeight: '54px',
                  primaryHighlightColor: data?.heading2?.highlightColor || theme.colors.secondary,
                  fontFamily: FontMapping.get(data?.heading2?.fontFamily),
                }}
              />
            )}
          </StepNumberContainer>

          {data?.heading3 && data?.heading3.isVisible && (
            <FlexBox
              backgroundImage={`url(${getCDNUrl(
                '/App/images/dream11-landing/dream11-heading-bg-v2.png'
              )})`}
              backgroundPosition={'center'}
              backgroundSize={'cover'}
              backgroundRepeat={'no-repeat'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'100%'}
              py={'6px'}
              width={'100%'}
            >
              <DescriptionField
                description={data?.heading3?.content}
                paragraphStyles={{
                  fontSize: data?.heading3?.fontSize ? `${data?.heading3?.fontSize}px` : '12px',
                  pb: 0,
                  lineHeight: '12px',
                  textAlign: 'center',
                  primaryHighlightColor: data?.heading3?.highlightColor || theme.colors.white,
                  color: data?.heading3?.fontColor || theme.colors.greyishWhite,
                  fontFamily: FontMapping.get(data?.heading3?.fontFamily),
                }}
              />
            </FlexBox>
          )}
        </FlexBox>

        <Dream11FFBottomWrapper
          px={'27px'}
          flexDirection={'column'}
          textAlign={'center'}
          position={'absolute'}
          bottom={15}
          width={'100%'}
          mb="5px"
        >
          {data?.bottomText && data?.bottomText?.isVisible ? (
            <DescriptionField
              description={data?.bottomText?.content}
              paragraphStyles={{
                fontSize: data?.bottomText?.fontSize ? `${data?.bottomText?.fontSize}px` : '14px',
                textAlign: 'center',
                primaryHighlightColor: data?.bottomText?.highlightColor || theme.colors.secondary,
                color: data?.heading3?.fontColor || theme.colors.white,
                fontFamily: FontMapping.get(data?.bottomText?.fontFamily),
              }}
            />
          ) : (
            <LandingFirstFoldBottomSteps
              step1={data?.bottomImage1}
              step2={data?.bottomImage2}
              step3={data?.bottomImage3}
            />
          )}

          <LandingButtons
            variant={data?.ctaVariation[0] || CTAVariantEnum.SINGLE_PRIMARY_CTA}
            primaryCta={data?.cta1}
            secondaryCta={data?.cta2}
          />
        </Dream11FFBottomWrapper>
      </FlexBox>

      {data?.ticker && data?.ticker?.isEnabled && (
        <HomeLandingTicker
          tickerData={data?.ticker?.headers}
          tickerImage={data?.ticker?.backgroundImage?.file?.url}
        />
      )}
      {data?.footerImage && (
        <CashbackSection image={data?.footerImage?.file?.url} name={data?.footerImage?.title} />
      )}
    </>
  )
}

export default LandingFirstFold
